import { LAP_VALUE } from '../constants';
const defineDotsDistance = (d1, d2) => Math.sqrt(Math.pow((d1[0] - d2[0]), 2) + Math.pow((d1[1] - d2[1]), 2));
class PointsTree {
    constructor(original, parent) {
        this.originalNode = original;
        this.right = null;
        this.left = null;
        this.parent = parent || null;
    }
    static buildTree(
    // we suppose that points are already sorted
    points, parent) {
        if (points.length === 0) {
            return null;
        }
        if (points.length === 1) {
            return new PointsTree(points[0], parent);
        }
        const median = Math.floor(points.length / 2);
        const node = new PointsTree(points[median], parent);
        node.left = PointsTree.buildTree(points.slice(0, median), node);
        node.right = PointsTree.buildTree(points.slice(median + 1), node);
        return node;
    }
    static defineClosestElements({ node, searchPoint, distance: searchDistance = LAP_VALUE, scaleFns, matchedNodes = [], }) {
        if (node === null)
            return;
        const { scaleX, scaleY } = scaleFns;
        let mappedPoint;
        let currentDistance;
        if (node === null || node === void 0 ? void 0 : node.originalNode) {
            mappedPoint = Object.assign({}, node.originalNode);
            currentDistance = defineDotsDistance([scaleX(mappedPoint.date), scaleY(mappedPoint.percent)], searchPoint);
        }
        if (typeof currentDistance === 'number' && currentDistance <= searchDistance) {
            matchedNodes.push(mappedPoint);
        }
        if (node.left) {
            this.defineClosestElements({
                node: node.left,
                matchedNodes,
                scaleFns: {
                    scaleX,
                    scaleY,
                },
                searchPoint,
                distance: searchDistance,
            });
        }
        if (node.right) {
            this.defineClosestElements({
                node: node.right,
                matchedNodes,
                scaleFns: {
                    scaleX,
                    scaleY,
                },
                searchPoint,
                distance: searchDistance,
            });
        }
    }
}
export default PointsTree;
