export const X_POINT_MATCHER = /[^:]*/;
export const Y_POINT_MATCHER = /:(.*?)-/;
export const CURVE_KEY_MATCHER = /(?<=-)[^-]+/;
// nearest point should not be far more than 12px from pointer
export const LAP_VALUE = 12;
export const TooltipElementHeight = 36;
export const TooltipElementWidth = 290;
export const TooltipElementsSmallXGap = 6;
export const TooltipElementsLargeXGap = 11;
export const TooltipElementsYGap = 4;
export const GraphMinWidth = 698;
export const GraphMinHeight = 273;
export const RightAxisWidth = 40;
export const BottomAxisHeight = 20;
export const CurveAxisGap = 12;
export const GridLineAdditionalLength = 24;
export const Margin = {
    top: 12,
    right: 12,
    bottom: 12,
    left: 24,
};
