import PointsTree from './PointsTree';
import { TooltipElementHeight, TooltipElementWidth, TooltipElementsLargeXGap, TooltipElementsSmallXGap, TooltipElementsYGap, } from '../constants';
export const defineDotsTree = (walletsData) => {
    const dataKeys = Object.keys(walletsData);
    let walletKeyPointer = 0;
    let pointPointer = 0;
    const points = [];
    while (walletKeyPointer < dataKeys.length) {
        if (walletsData[walletKeyPointer][pointPointer]) {
            points.push(walletsData[walletKeyPointer][pointPointer]);
        }
        if (pointPointer === walletsData[walletKeyPointer].length - 1) {
            walletKeyPointer += 1;
            pointPointer = 0;
        }
        else {
            pointPointer += 1;
        }
    }
    points.sort((a, b) => a.date.getTime() - b.date.getTime());
    const tree = PointsTree.buildTree(points, null);
    return { tree, points };
};
export const getDefaultHoveredGraphsObj = (walletsData) => Object.keys(walletsData).reduce((acc, walletKey) => {
    acc[Number(walletKey)] = false;
    return acc;
}, {});
const defineYEdgePoints = (points) => {
    let min = +Infinity;
    let max = -Infinity;
    points.forEach((point) => {
        min = Math.min(min, point.percent);
        max = Math.max(max, point.percent);
    });
    return { min, max };
};
export const defineTooltipPlacement = ({ elementsNumber, scaleFns, points, curveHeight, curveWidth, }) => {
    let tooltipTop;
    let tooltipLeft;
    let elementsXGap;
    const { scaleX, scaleY } = scaleFns;
    const { min, max } = defineYEdgePoints(points);
    const tooltipContainerWidth = Math.ceil((elementsNumber) / 6) * TooltipElementWidth +
        5 * (Math.ceil((elementsNumber) / 6) - 1);
    const tooltipContainerHeight = elementsNumber >= 6 ?
        (TooltipElementHeight * 6) + (TooltipElementsYGap * 5) :
        (TooltipElementHeight * elementsNumber) + (TooltipElementsYGap * (elementsNumber - 1));
    // along x
    const shouldPlaceAfterCursor = scaleX(points[0].date) < tooltipContainerWidth;
    const shouldPlaceBeforeCursor = scaleX(points[0].date) + tooltipContainerWidth > curveWidth;
    // along y
    const shouldPlaceAboveCursor = ((scaleY(min) + scaleY(max)) / 2 + tooltipContainerHeight) > curveHeight;
    const shouldPlaceUnderCursor = ((scaleY(min) + scaleY(max)) / 2 - (tooltipContainerHeight / 2)) < 0;
    if (shouldPlaceAfterCursor) {
        tooltipLeft = scaleX(points[0].date) + 9;
        elementsXGap = TooltipElementsSmallXGap;
    }
    else if (shouldPlaceBeforeCursor) {
        tooltipLeft = scaleX(points[0].date) - TooltipElementWidth - 9;
        elementsXGap = TooltipElementsSmallXGap;
    }
    else if (elementsNumber > 6) {
        tooltipLeft = scaleX(points[0].date) - (tooltipContainerWidth / 2);
        elementsXGap = TooltipElementsLargeXGap * 2;
    }
    else {
        tooltipLeft = scaleX(points[0].date) + 9;
        elementsXGap = TooltipElementsSmallXGap;
    }
    if (shouldPlaceAboveCursor) {
        tooltipTop = curveHeight - tooltipContainerHeight;
    }
    else if (shouldPlaceUnderCursor) {
        tooltipTop = scaleY(max);
    }
    else {
        tooltipTop = ((scaleY(max) + scaleY(min)) / 2) - (tooltipContainerHeight / 2);
    }
    return {
        top: tooltipTop,
        left: tooltipLeft,
        xGap: elementsXGap,
        yGap: TooltipElementsYGap,
        ellipseLeft: scaleX(points[0].date),
        ellipseTop: scaleY(max),
    };
};
export const defineCurveOpacity = (hoveredObj, currentKey) => {
    const hasHoveredCurves = Object.keys(hoveredObj)
        .some((key) => hoveredObj[Number(key)] === true);
    if (!hasHoveredCurves) {
        return 1;
    }
    return hoveredObj[Number(currentKey)] ? 1 : 0.4;
};
